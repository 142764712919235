// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-consulting-services-js": () => import("./../../../src/pages/consulting-services.js" /* webpackChunkName: "component---src-pages-consulting-services-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cyber-health-check-chc-js": () => import("./../../../src/pages/cyber-health-check-chc.js" /* webpackChunkName: "component---src-pages-cyber-health-check-chc-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-penetration-and-vulnerability-testing-js": () => import("./../../../src/pages/penetration-and-vulnerability-testing.js" /* webpackChunkName: "component---src-pages-penetration-and-vulnerability-testing-js" */),
  "component---src-pages-policy-development-js": () => import("./../../../src/pages/policy-development.js" /* webpackChunkName: "component---src-pages-policy-development-js" */)
}

